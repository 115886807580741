<template>
  <div class="titBox">
    <div>
      <img src="../../../static/index/titleRed.png" alt="">
      <span v-if="titleContent.length<=3">{{titleContent[0]}}</span>
      <img class="titImg" v-if="titleContent.length>3" src="../../../static/index/titImg1.png" alt="">
      <!-- <img class="textPosition" src="../../../static/index/textPosition.png" alt=""> -->
    </div>
    <p v-if="titleContent[1]" @click="btn(titleContent[2])">查看更多</p>
  </div>
</template>

<script>
// 引入eventBus
import eventBusMitter from '../../../utils/eventbus';
export default {
  name:'tit',
  props:['titleContent'],
  data() {
    return {
      key:Number,
    }
  },
  created(){
  },
  methods:{
    btn(attr){
      if(attr == 1){
        this.$router.push({
          name:'Details',
        })
        // 通过eventBus传给layout数据
        eventBusMitter.emit('eventBus', {key:'5'})
      }else{
        this.$router.push({
          name:'Market',
        })
        eventBusMitter.emit('eventBus', {key:'4'})
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .titBox{
    max-width: 1200px;
    margin: 60px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div{
      display: flex;
      align-items: center;
      span{
        color: #000000;
        font-size: 34px;
        margin-left: 22px;
      }
      .titImg{
        height: 45px;
        margin-left: 22px;
      }
    }
    p{
      width: 130px;
      height: 50px;
      border: 1px solid #d6c4ae;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b09a8f;
      cursor: pointer;
    }
    .textPosition{
      width: 249px;
      margin-left: 30px;
    }
  }
  @media screen and (max-width: 500px) {
    .titBox{
      width: 100%;
      margin: 30px auto;
      div{
        span{
          font-size: 22px;
        }
        .titImg{
          height: 29px;
          margin-left: 22px;
        }
      }
      p{
        width: 110px;
        height: 35px;
        border-radius: 15px;
        font-size: 14px;
      }
    }
  }
</style>